import { useTranslation } from "react-i18next";

import {
  Button,
  CardCollapsible,
  Flex,
  LoginIcon,
  Typography,
} from "@vapaus/ui-v2";

export function LogInNow() {
  const { t } = useTranslation();

  const signUpLink = `https://${window.location.host.replace(
    "savings",
    "user",
  )}/sign-up`;

  return (
    <CardCollapsible
      icon={<LoginIcon />}
      title={t("calculator:logInNow.title")}
    >
      <Flex direction="column" gap="md">
        <Typography variant="bodyLg">
          {t("calculator:logInNow.description")}
        </Typography>
        <a href={signUpLink} target="_blank" rel="noreferrer">
          <Button xsFullWidth>{t("calculator:logInNow.button")}</Button>
        </a>
      </Flex>
    </CardCollapsible>
  );
}
